.FlipClock {
    text-align: center;
}

.FlipClock .M-Flipper {
    margin: 0 3px;
}
  
.FlipClock em {
    display: inline-block;
    line-height: 60px;
    font-size: 25px;
    font-style: normal;
    vertical-align: top;
}
  